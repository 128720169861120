export const grappa = [
  {
    name: 'Grappa Nonino',
    price: 420,
    id: 1,
  },
  {
    name: 'Vite D”Oro',
    price: 560,
    id: 2,
  },
  {
    name: 'Grappa Moscato',
    price: 720,
    id: 3,
  },
  {
    name: 'Pisco Chile / Peru',
    price: 380,
    id: 4,
  },
  {
    name: 'Ouzo / Greece',
    price: 350,
    id: 5,
  },
  {
    name: 'Weiser Shnaps Apricot / Plum / Williams pear',
    price: 460,
    id: 6,
  },
]
