export const irishWhiskey = [
  {
    name: 'Bushmills',
    price: 380,
    id: 1,
  },
  {
    name: 'Bushmills Black Bush',
    price: 550,
    id: 2,
  },
  {
    name: 'Teeling Small Batch',
    price: 560,
    id: 3,
  },
  {
    name: 'Bagots Triple Batch',
    price: 600,
    id: 4,
  },
  {
    name: 'Writers Tears',
    price: 720,
    id: 5,
  },
]
