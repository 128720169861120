export const redWineBottle = [
  {
    name: 'Lopez De Haro, Rioha',
    description: 'Spain, 2022',
    price: 3000,
    id: 1,
  },
  {
    name: 'Garnacha / Gurpegui Art',
    description: 'Spain, 2022',
    price: 3000,
    id: 2,
  },
  {
    name: 'Shiraz / The last stand',
    description: 'South Australia, 2022',
    price: 3500,
    id: 3,
  },
  {
    name: 'Nero D”Avola / Cusumano',
    description: 'Italy/Sicilia, 2021',
    price: 3500,
    id: 5,
  },
  {
    name: 'Malbec / Amalaya',
    description: 'Argentina, 2022',
    price: 3600,
    id: 6,
  },
  {
    name: 'Haras De Pirque / Reserva',
    description: 'Chile, 2018',
    price: 3500,
    id: 7,
  },
  {
    name: 'Primitivo / Neprica',
    description: 'Italy, 2022',
    price: 3900,
    id: 8,
  },
  {
    name: 'La Bella Sedara / Donnafugata',
    description: 'Italy/Sicilia, 2021',
    price: 3950,
    id: 9,
  },
  {
    name: 'Trentangeli, Castel D Monte',
    description: 'Italy, 2018',
    price: 4300,
    id: 10,
  },
  {
    name: 'Cune Reserva / Rioha',
    description: 'Spain, 2018',
    price: 4650,
    id: 11,
  },
  {
    name: 'Zweigelt / Kracher',
    description: 'Austria, 2019',
    price: 5000,
    id: 12,
  },
  {
    name: 'Tempranillo / Finca El Encial',
    description: 'Spain, 2019',
    price: 5200,
    id: 13,
  },
  {
    name: 'Shiraz Organic / Yalumba ',
    description: 'South Australia, 2019',
    price: 5300,
    id: 14,
  },
  {
    name: 'Pinot Noir / Gamla- Kosher',
    description: 'Israel, 2022',
    price: 5950,
    id: 15,
  },
  {
    name: 'Frappato / Tenuta Regaleali',
    description: 'Italy/Sicilia, 2022',
    price: 6500,
    id: 16,
  },
  {
    name: 'Rosso Di Montalcino / Coldorcia',
    description: 'Italy, 2020',
    price: 5900,
    id: 17,
  },
  {
    name: 'Sassi Chiusi / Bertinga',
    description: 'Italy, 2015',
    price: 5900,
    id: 18,
  },
  {
    name: 'Botrosecco, Maremma',
    description: 'Italy, 2020',
    price: 6550,
    id: 19,
  },
  {
    name: 'Carmenere / Terroir Hunter',
    description: 'Chile, 2020',
    price: 6800,
    id: 20,
  },
  {
    name: 'Chateau Haut-Madrac / Borie - Manoux',
    description: 'France, 2018',
    price: 7900,
    id: 21,
  },
  {
    name: 'La Madonnia / Toscana Rosso',
    description: 'Italy, 2019',
    price: 8350,
    id: 22,
  },
  {
    name: 'NOA / Cusumano',
    description: 'Italy / Sicilia, 2019',
    price: 8500,
    id: 23,
  },
  {
    name: 'KOREM / Bovale',
    description: 'Italy / Sardinia, 2020',
    price: 9000,
    id: 24,
  },
  {
    name: 'Historic / Terroir | Priorat',
    description: 'Spain, 2021',
    price: 9500,
    id: 25,
  },
  {
    name: 'Nebbiolo / Barbaresco | Michele Chiarlo',
    description: 'Italy, 2019',
    price: 9900,
    id: 26,
  },
  {
    name: 'Chateau Mont-Redon / Chateauneuf-du-pape',
    description: 'France, 2021',
    price: 12000,
    id: 27,
  },
  {
    name: 'Terra De Cuques / Terroir Al Limit | Priorat',
    description: 'Spain, 2021',
    price: 13000,
    id: 28,
  },
  {
    name: 'Kurni / Oasi degli Angeli',
    description: 'Italy, 2017',
    price: 20500,
    id: 29,
  },
]
