export const coffee = [
  {
    name: 'Эспрессо',
    price: 180,
    id: 1,
  },
  {
    name: 'Американо',
    price: 200,
    id: 2,
  },
  {
    name: 'Капучино',
    price: 250,
    id: 3,
  },
  {
    name: 'Латте',
    price: 250,
    id: 4,
  },
  {
    name: 'Матча',
    price: 260,
    id: 5,
  },
  {
    name: 'Флэт уайт',
    price: 200,
    id: 6,
  },
  {
    name: 'Раф кофе',
    price: 250,
    id: 7,
  },
  {
    name: 'Кофе с халвой',
    price: 250,
    id: 8,
  },
  {
    name: 'Кофе на альтернативном молоке (миндальное/кокосовое)',
    price: 350,
    id: 9,
  },
  {
    name: 'Какао',
    price: 320,
    id: 10,
  },
  {
    name: 'Эспрессо & Тоник',
    price: 280,
    id: 11,
  },
]
