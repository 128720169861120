export const water = [
  {
    name: 'Сан Бенедетто / Italy / 0,5 л',
    price: 460,
    id: 1,
  },

  {
    name: 'Coca-Cola / Coca-Cola zero / Sprite / Schwepps Tonic / 0,33 л',
    price: 300,
    id: 2,
  },
  {
    name: 'Тоник J.Gasco / Italy / 0.2 л',
    price: 380,
    id: 3,
  },
]
