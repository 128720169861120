export const japanWhiskey = [
  {
    name: 'Nikka Days blended Whisky',
    price: 760,
    id: 1,
  },
  {
    name: 'Togouchi blended',
    price: 720,
    id: 2,
  },
  {
    name: 'Nikka Coffe grain',
    price: 1950,
    id: 3,
  },
  {
    name: 'Taketsuru Pure Malt',
    price: 2500,
    id: 4,
  },
  {
    name: 'HIBIKI Single Malt',
    price: 2500,
    id: 5,
  },
]
