export const vodka = [
  {
    name: 'Архангельская',
    price: 180,
    id: 1,
  },
  {
    name: 'Онегин',
    price: 300,
    id: 2,
  },
  {
    name: 'Shmidt',
    price: 320,
    id: 3,
  },
]
