export const whiteWineBottle = [
  {
    name: 'Gruner Veltliner / Nastl',
    description: 'Australia, 2022',
    price: 3200,
    id: 1,
  },
  {
    name: 'Sauvignon / Misty Cliff',
    description: 'NZ, 2022',
    price: 3500,
    id: 2,
  },
  {
    name: 'Riesling / SteinBock',
    description: 'Germany, 2021',
    price: 3500,
    id: 3,
  },
  {
    name: 'Sauvignon / The Whale Watcer',
    description: 'NZ, 2022',
    price: 3700,
    id: 4,
  },
  {
    name: 'Pinot Grigio / Priara',
    description: 'Italy, 2022',
    price: 3800,
    id: 5,
  },
  {
    name: 'Sauvignon / Marques de Riscal',
    description: 'Spain, 2022',
    price: 4000,
    id: 6,
  },
  {
    name: 'Sauvignon / Lone Kauri',
    description: 'NZ, 2022',
    price: 4200,
    id: 7,
  },
  {
    name: 'Weissburgunder / Mertz',
    description: 'Germany, 2021',
    price: 4200,
    id: 8,
  },
  {
    name: 'Riesling / Balbach',
    description: 'Germany, 2022',
    price: 5000,
    id: 9,
  },
  {
    name: 'Riesling / Coopers Creek',
    description: 'NZ, 2021',
    price: 5400,
    id: 10,
  },
  {
    name: 'Chardonnay / Arboleda',
    description: 'Chile, 2021',
    price: 5900,
    id: 11,
  },
  {
    name: 'Riesling Terrassen / Brundlmayer',
    description: 'Australia, 2022',
    price: 6200,
    id: 12,
  },
  {
    name: 'Gavi / La Scolca',
    description: 'Italy, 2022',
    price: 6450,
    id: 13,
  },
  {
    name: 'Gewurztraminer / Trimbah',
    description: 'France, 2020',
    price: 6500,
    id: 14,
  },
  {
    name: 'Sauternes / De-Rieussec',
    description: 'France, 2019',
    price: 6500,
    id: 15,
  },
  {
    name: 'Sauvignon / Chapel Peak',
    description: 'NZ, 2022',
    price: 6800,
    id: 16,
  },
  {
    name: 'Gewurztraminer / Terlan',
    description: 'Italy, 2022',
    price: 7200,
    id: 17,
  },
  {
    name: 'Gavi dei Gavi / La Scolca',
    description: 'Italy, 2021',
    price: 7200,
    id: 18,
  },
  {
    name: 'Weisburgunder / nik weis',
    description: 'Germany, 2022',
    price: 7500,
    id: 19,
  },
  {
    name: 'Chablis / Jean-Mark Brocard',
    description: 'France, 2022',
    price: 8500,
    id: 20,
  },
]
