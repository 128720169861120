export const americanWhiskey = [
  {
    name: 'Jim Beam',
    price: 420,
    id: 1,
  },
  {
    name: "Jack Daniel's",
    price: 450,
    id: 2,
  },
  {
    name: 'The WiseMan',
    price: 850,
    id: 3,
  },
  {
    name: "Makes's Mark",
    price: 850,
    id: 4,
  },
  {
    name: 'Woodford Reserve',
    price: 720,
    id: 5,
  },
  {
    name: 'Copper Fox Ry',
    price: 1140,
    id: 6,
  },
]
