export const lemonades = [
  {
    name: 'Черника & Маракуйя',
    price: '380/720',
    id: 1,
  },
  {
    name: 'Персик & Лаванда',
    price: '380/720',
    id: 2,
  },
  {
    name: 'Манго & Облепиха',
    price: '380/720',
    id: 3,
  },
  {
    name: 'Клубника & Базилик',
    price: '380/720',
    id: 4,
  },
  {
    name: 'Цитрус & Клубника',
    price: '380/720',
    id: 5,
  },
]
