export const teaFruit = [
  {
    name: 'Ежевика | Вишня / Мед',
    price: 450,
    id: 1,
  },
  {
    name: 'Облепиха | Липа / Мед',
    price: 450,
    id: 2,
  },
  {
    name: 'Малина | Имбирь / Мед',
    price: 450,
    id: 3,
  },
  {
    name: 'Ананас | Клубника / Мед',
    price: 450,
    id: 4,
  },
]
