export const cocktails = [
  {
    name: 'Apple Tini',
    description: 'fresh apple / vodka / green apple liquer / lime',
    price: 630,
    id: 1,
  },
  {
    name: 'Woody Apple',
    description: 'calvados-pinecone jam infuse / coconut / lime',
    price: 630,
    id: 2,
  },
  {
    name: 'Pineapple Bellini',
    description: 'orange liquer infuse / pineapple / peach / sparkling wine',
    price: 630,
    id: 3,
  },
  {
    name: 'Pussy killer',
    description: 'citrus vodka / cowberry / coconut / vermouth',
    price: 630,
    id: 4,
  },
  {
    name: 'Chiquita',
    description: 'tequila / lime leaves / lichi / sweet & sour',
    price: 630,
    id: 5,
  },
  {
    name: 'Tiger Mai Tai',
    description: 'dark rum / falernum / pineapple / orgeat',
    price: 630,
    id: 6,
  },
  {
    name: 'Elderberry Honey',
    description: 'vodka / elderflower / buckwheat honey',
    price: 630,
    id: 7,
  },
  {
    name: 'Aperol JR.',
    description: 'perse / lemongrass / citrus foam',
    price: 630,
    id: 8,
  },
  {
    name: 'Bergamo',
    description: 'bergamot / peach liquer / pastila / lime cordial',
    price: 630,
    id: 9,
  },
  {
    name: 'Breslavskaya',
    description: 'pink rum / jasmine/ orange',
    price: 630,
    id: 10,
  },
  {
    name: 'ANGEL 8 SOUR',
    description: 'bourbon / pear puree / amaretto / lemon',
    price: 630,
    id: 11,
  },
  {
    name: 'Fade To Black',
    description: 'laphroaig 10 / jerez pedro Ximenez / prunes',
    price: 630,
    id: 12,
  },
  {
    name: 'AVIATION',
    description: 'gin / violet / maraschino / lemon',
    price: 630,
    id: 13,
  },
  {
    name: 'Electric Wet dog',
    description: 'gin / electric flower / suze / dry vermouth',
    price: 630,
    id: 14,
  },
  {
    name: 'Green Lemon',
    description: 'limoncello / basil / aloe / lime / sparkling wine',
    price: 630,
    id: 15,
  },
  {
    name: 'GIN BASIL SMASH',
    description: 'gin / basil / lemon / sugar',
    price: 630,
    id: 16,
  },
  {
    name: 'BARSELONA KISS',
    description: 'Rum / Raspberry / cream / cacao',
    price: 630,
    id: 17,
  },
  {
    name: 'Pusher man',
    description: 'brandy / whisky / orange / maple syrup',
    price: 630,
    id: 18,
  },
  {
    name: 'ORANGE SOUR',
    description: 'pisco / triple sec / lemon / sugar',
    price: 630,
    id: 19,
  },
  {
    name: 'REGGAE RUM PUNCH',
    description: 'rum / raspberry / lemon',
    price: 650,
    id: 20,
  },
  {
    name: 'ZOMBIE',
    description: 'rum / angostura bitter / grapefruit',
    price: 650,
    id: 21,
  },
  {
    name: 'DAIQUIRI DRY',
    description: 'rum / passionfruit / sugar',
    price: 600,
    id: 22,
  },
  {
    name: 'Tropical daiquiri',
    description: 'spiced mauritius rum / lime / sugar',
    price: 650,
    id: 23,
  },
  {
    name: 'Citadel',
    description:
      'infusion of fruits gin citadelle / orange liquer / sweet & sour',
    price: 650,
    id: 24,
  },
]
