export const milkShakes = [
  {
    name: 'Фисташка | Phistatio',
    description: 'фисташковое мороженое / молоко / сливки',
    price: '450',
    id: 1,
  },
  {
    name: 'Халва | Halva',
    description: 'мороженое / молоко / сливки / халва из белого шоколада',
    price: '450',
    id: 2,
  },
  {
    name: 'Oreo',
    description: 'мороженое / молоко / сливки / печенье орео',
    price: '450',
    id: 3,
  },
]
