export const scotchWhiskeyBlend = [
  {
    name: 'Mossburn Cask Bill #1',
    price: 580,
    id: 1,
  },
  {
    name: 'Mossburn Cask Bill #2',
    price: 580,
    id: 2,
  },
  {
    name: 'Monkey Sholder / malt blend',
    price: 800,
    id: 3,
  },
]
