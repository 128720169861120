export const calvados = [
  {
    name: 'Chateau du Breuil "Fine" Calvados',
    price: 480,
    id: 1,
  },
  {
    name: 'Chаteau du Breuil VSOP',
    price: 750,
    id: 2,
  },
]
