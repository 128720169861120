export const hotAlco = [
  {
    name: 'Hot Buttered Rum Pistatio',
    description: 'ром / яблоко / специи / пена из фисташек',
    price: 550,
    id: 1,
  },
  {
    name: 'Peach Toddy',
    description: 'ром / грейпфрут / персик',
    price: 550,
    id: 2,
  },
  {
    name: 'Bishop',
    description: 'портвейн, мед, апельсин',
    price: 550,
    id: 3,
  },
  {
    name: 'Глинтвейн / red | white',
    description: 'вино / бренди / фрукты / специи',
    price: 550,
    id: 4,
  },
]
