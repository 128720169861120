export const brandy = [
  {
    name: 'Barbadillo B&B / brandy',
    price: 360,
    id: 1,
  },
  {
    name: 'Marlet Vs / Cognac',
    price: 450,
    id: 2,
  },
  {
    name: 'Darrose / 8 / Bas - Armagnac',
    price: 750,
    id: 3,
  },
  {
    name: 'H By Hine VSOP',
    price: 950,
    id: 4,
  },
  {
    name: 'Hine Cigare Reserve XO / Cognac',
    price: 2250,
    id: 5,
  },
]
